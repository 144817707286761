<template>
  <li
    ref="refTarget"
    :style="styles"
    v-bind="$attrs"
    class="tag-text"
    :class="isViewNew && 'tag-text__view-new'"
    @keydown.enter.space.prevent="$emit('click', $event)"
    @click="$emit('click', $event)"
  >
    <CloudTagIcon
      v-if="icon"
      :icon="icon"
      :is-view-new="isViewNew"
    />

    <div class="tag-text__cntent">
      {{ label }}
    </div>

    <CloudTagActiveIcon
      v-if="active"
      :color="styles.color"
    />
  </li>
</template>

<script setup>
import { ref, toRef, computed } from 'vue'

// components
import CloudTagIcon from '../CloudTagIcon.vue'
import CloudTagActiveIcon from '../CloudTagActiveIcon.vue'

// hooks
import { useHover, useCccStyles } from './hooks/index.js'

defineEmits(['click'])
const props = defineProps({
  isViewNew: {
    type: Boolean,
    default: false
  },
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => ({})
  }
})

const refTarget = ref()
const isHover = useHover(refTarget)
const icon = computed(() => props.item.cccConfig?.displayContent?.icon?.src || '')
const styles = useCccStyles(toRef(props, 'active'), toRef(props, 'isViewNew'), isHover, props.item.cccConfig)
const label = computed(() => props.item.quickShipText || props.item.cccConfig?.displayContent?.labelLang || props.item.tag_name)

</script>

<style scoped lang="less">
  .tag-text {
    font-size: 12px;
    padding: 6px 12px;
    margin-right: 8px;
    line-height: 15px;

    /* stylelint-disable-next-line declaration-no-important */
    float: left !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    height: 27px;
    border: 1px solid #e5e5e5;
    margin: 0 10px 0 0 /*rtl: ignore*/;
    color: #666;
    cursor: pointer;
    background-color: #fff;
    word-break: break-word;
    display: flex;
    align-items: center;

    &__view-new {
      line-height: 16px;
      border: none;
      padding: 6px 12px 6px 8px;
      margin-right: 12px;
      height: 36px;
      background: #f6f6f6;
      margin-bottom: 12px;
    }
  }
</style>
