<template>
  <div
    :class="[
      'sort-bar',
      `sort-bar__${labelWidth}`,
      showGoodsNum ? 'sort-bar_picks' : '',
      sortPosition == 'top' ? 'sort-bar_top' : '',
      filterSelected ? 'sort-bar_filter-align' : ''
    ]"
  >
    <div
      v-if="showGoodsNum"
      class="sort-bar__left"
    >
      {{ sum }} {{ language.SHEIN_KEY_PC_15778 }}
    </div>

    <div class="sort-bar__inner">
      <label 
        v-if="!isViewNew"
        class="sort-bar__title"
      >
        {{ language.SHEIN_KEY_PC_15779 }}
      </label>
      <div class="sort-bar__dropdown not-fsp-element">
        <s-select
          ref="selectEl"
          v-model="selectValueStr"
          role="application"
          :aria-hidden="!!selectOpen"
          :aria-expanded="!!selectOpen"
          :aria-haspopup="!!selectOpen"
          :aria-label="language.SHEIN_KEY_PC_15779 + selectLabel"
          name="sortBarSelect"
          :inputable="false"
          :hoverable="false"
          tabindex="0"
          force-echo
          :force-echo-label="ssrLabelText"
          @change="clickSort"
        >
          <template
            v-if="isViewNew"
            #prefix
          >
            <label class="sort-bar__title">{{ language.SHEIN_KEY_PC_15779 }}</label>
          </template>

          <ClientOnly>
            <s-select-option
              v-for="item in sortList"
              :key="item.value"
              :value="item.value"
              class="not-fsp-element"
              :label="item.label"
              :aria-checked="selectValueStr === item.value"
              tabindex="0"
              role="button"
              :aria-label="item.label"
            />
          </ClientOnly>
        </s-select>
      </div>
    </div>
  </div>
</template>

<script>
import { SSelect } from '@shein-aidc/sui-select'
import { SSelectOption } from '@shein-aidc/sui-select-option'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { parseQueryString } from '@shein/common-function'
import { mapState, mapGetters, mapMutations } from 'vuex'
import mixin from './mixins'
import selectAda from './mixins/selectAda'
import { daEventCenter } from 'public/src/services/eventCenter'

export default {
  name: 'SortBar',
  components: {
    SSelect,
    SSelectOption,
    ClientOnly,
  },
  mixins: [mixin, selectAda],
  props: {
    sortPosition: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: 'goodsList',
    },
    filterSelected: {
      type: Boolean,
      default: false
    },
    isViewNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortList: [],
      selectValueStr: '',
      ssrLabelText: '',
    }
  },
  computed: {
    ...mapState(['lang', 'language', 'listAbtResult']),
    ...mapGetters(['searchKeywords', 'catInfo', 'sum']),
    isPicks() {
      return this.catInfo.type == 'picks'
    },
    selectLabel() {
      return (
        this.sortList.find((_) => _.value == this.selectValueStr)?.label || ''
      )
    },
    showGoodsNum() {
      return this.isPicks && `${this.listAbtResult.HideGoodsNum?.p}` === 'false'
    },
    isShowMostPopular() {
      return this.listAbtResult?.MostPopular?.p?.ShowMostPopular === 'YES'
    },
    // label宽度，根据多语言判断
    labelWidth () {
      if (['eurbg'].includes(this.lang)) return 100
      if (['at', 'br', 'pt', 'ch', 'de', 'vn', 'euqssk', 'euqscs-cz', 'euqsel-gr'].includes(this.lang)) return 90

      return 70
    }
  },
  created() {
    this.initSortData()
  },
  methods: {
    ...mapMutations(['jumpUrl']),
    // 初始化sort下拉数据
    initSortData() {
      let selectedIndex = 0

      this.sortList = [
        { value: '0', label: this.language.SHEIN_KEY_PC_15621, keyEn: 'Recommend' },
        this.isShowMostPopular ? { value: '8', label: this.language.SHEIN_KEY_PC_15622, keyEn: 'Most Popular' } : {},
        { value: '9', label: this.language.SHEIN_KEY_PC_15623, keyEn: 'New Arrivals' },
        { value: '7', label: this.language.SHEIN_KEY_PC_17089, keyEn: 'Top Rated' },
        { value: '10', label: this.language.SHEIN_KEY_PC_15624, keyEn: 'Price Low To High' },
        { value: '11', label: this.language.SHEIN_KEY_PC_15625, keyEn: 'Price High To Low' },
      ].filter((item) => item.value)

      if (this.catInfo?.sort) {
        this.sortList.forEach((item, index) => {
          if (item.value == this.catInfo.sort) {
            selectedIndex = index
          }
        })
      }

      // 选中的sort筛选
      this.selectValueStr = this.sortList[selectedIndex].value
      this.ssrLabelText = this.sortList[selectedIndex].label || ''
    },
    clickSort({ value }) {
      // 修改query上的sort
      const sortValue = Number(value) ? { sort: value } : { sort: undefined } // 默认recommend排序时，不传值
      this.queryObj = Object.assign(
        parseQueryString(location.search), 
        sortValue,
        {
          source: 'sort',
          sourceStatus: 1,
        }
      )

      const activeItem = this.sortList.find((i) => i.value == value)

      // anlysis s
      daEventCenter.triggerNotice({
        daId: '1-4-2-7',
        extraData: {
          label: activeItem.label,
          keyEn: activeItem.keyEn,
          sort: activeItem.value,
        },
      })
      // analysis e

      // mixin方法
      this.handleJumpUrl()
      this.$emit('clickSort', { queryObj: this.queryObj, activeItem })
    },
    // 父组件调用，改变选中值
    updateValue(value) {
      this.selectValueStr = value
    }
  },
  emits: ['clickSort']
}
</script>

<style lang="less">
.sort-bar {
  height: 70px;
  padding: 15px 0;
  &_picks {
    .flexbox();
    .pack-center();
    .align-center();
    .space-between();
  }
  &_top {
    margin: 0;
  }
  &_filter-align {
    padding-top: 0;
    height: 55px;
  }
  &__left {
    color: #999;
  }
  &__inner {
    .fr();
  }
  &__title {
    .fl();
    padding: 0;
    height: 40px;
    line-height: 40px;
  }
  &__dropdown {
    .fl();
    .sui-select {
      width: 150px;
      z-index: 10; // 增加层级，避免被实时推荐面板遮挡
    }
  }
}

@sortBar70: 70px;
.sort-bar__70{
  .sui-select {
    .sui-input__prefix-inner {
      width: @sortBar70;
      padding: 0;
    }

    .sui-input__inner-prefix {
      padding-left: @sortBar70;
    }
  }
}

@sortBar80: 80px;
.sort-bar__80{
  .sui-select {
    .sui-input__prefix-inner {
      width: @sortBar80;
      padding: 0;
    }

    .sui-input__inner-prefix {
      padding-left: @sortBar80;
    }
  }
}

@sortBar90: 90px;
.sort-bar__90{
  .sui-select {
    .sui-input__prefix-inner {
      width: @sortBar90;
      padding: 0;
    }

    .sui-input__inner-prefix {
      padding-left: @sortBar90;
    }
  }
}

@sortBar100: 100px;
.sort-bar__100{
  .sui-select {
    .sui-input__prefix-inner {
      width: @sortBar100;
      padding: 0;
    }

    .sui-input__inner-prefix {
      padding-left: @sortBar100;
    }
  }
}
</style>
