// ADA需求
export default {
  data() {
    return {
      selectFocus: false, // 是否处于焦点状态
      hoverTimer: null,
      selectOpen: false,
    }
  },
  mounted() {
    this.selectInit()
  },
  unmounted() {
    const { _el } = this
    if (!_el) return
    _el.removeEventListener('mouseenter', this.mouseenter)
    _el.removeEventListener('mouseleave', this.mouseleave)
    _el.removeEventListener('focus', this.onfocus)
    _el.removeEventListener('blur', this.onblur)
    _el.removeEventListener('keydown', this.keydown)
  },
  methods: {
    async selectInit() {
      await this.$nextTick()
      this._el = this.$refs?.selectEl.$el
      const { _el } = this
      if (!_el) return
      _el.addEventListener('mouseenter', this.mouseenter)
      _el.addEventListener('mouseleave', this.mouseleave)
      _el.addEventListener('focus', this.onfocus)
      _el.addEventListener('blur', this.onblur)
      _el.addEventListener('keydown', this.keydown)
      _el.children[1].addEventListener('keydown', this.keydownOptions)
    },
    mouseenter() {
      if (this.hoverTimer) {
        clearTimeout(this.hoverTimer)
      }
      this.$refs?.selectEl?.changeVisible(true)
    },
    mouseleave() {
      if (this.hoverTimer) {
        clearTimeout(this.hoverTimer)
      }
      this.hoverTimer = setTimeout(() => {
        this.$refs?.selectEl?.changeVisible(false)
      }, 100)
    },
    onfocus() {
      this.selectFocus = true
    },
    onblur() {
      this.selectFocus = false
    },
    keydown(event) {
      const e = event || window.event || arguments.callee.caller.arguments[0]
      if (e && e.keyCode == 13 && this.selectFocus) {
        // 回车
        this.mouseenter() // 显示options
        this.selectOpen = true
      } else if (this.selectFocus && e.keyCode == 27) {
        // esc
        this.mouseleave()
        this.selectOpen = false
      }
    },
    keydownOptions(e) {
      if (e.keyCode == 27) {
        // esc
        this.mouseleave()
        this.selectOpen = false
      }
    },
  },
}
