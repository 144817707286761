import { mapState, mapGetters, mapMutations } from 'vuex'
import { parseQueryString } from '@shein/common-function'
import filterAna from 'public/src/pages/product_list_v2/analysis/filter.js'
import {
  getIsCustomAttr,
  DAILY_KEY, CATE_KEY, TSP_KEY, ATTR_KEY, PRICE_KEY, BRAND_KEY
} from 'public/src/pages/product_list_v2/utils/index'

export default {
  computed: {
    ...mapState([
      'Results',
      'retainQueryObj'
    ]),
    ...mapGetters([
      'catInfo',
      'searchKeywords',
      'isCccxFilterConfig',
      'selectedAttrs',
      'selectedTsps'
    ]),
    selectedFilter() {
      return this.Results?.selectedFilter || []
    }
  },
  data () { 
    return {
      queryObj: {},
    }
  },
  methods: {
    ...mapMutations([
      'jumpUrl',
      'assignState',
      'deleteSelectedFilter',
      'clearAllSelectedFilter'
    ]),
    handleUrl(isClickAttr) {
      if (
        this.searchKeywords.is_suggest_res &&
        this.searchKeywords.suggest_words
      ) {
        this.jumpUrl({
          localUrl: `${location.origin}/pdsearch/${this.searchKeywords.suggest_words}`,
          queryObj: this.queryObj,
          isClickAttr,
        })
      } else {
        this.jumpUrl({ queryObj: this.queryObj, isClickAttr })
      }
    },
    // SideFilter/FilterResult 复用

    // 筛选后跳转
    handleJumpUrl(isClickAttr) {
      this.queryObj.page = 1

      this.handleUrl(isClickAttr)
    },
    deleteQueryObj(retainObj) {
      Object.keys(this.queryObj).forEach((key) => {
        if (!retainObj[key]) {
          this.queryObj[key] = undefined
        }
      })
    },
    clearStaticAttrSetId() {
      this.Results.staticFilterId = ''
      this.Results.staticFilterType = ''
    },
    setStaticAttrSetId(value, type) {
      this.Results.staticFilterType = type
      const { label_id, operationType } = value
      let id = ''
      let isBreak = false
      let isSelected = operationType && operationType !== 'delete'

      type === ATTR_KEY
        ? this.Results.filterAttrs?.forEach(item => {
          if (isBreak) return
          const isCustomAttr = getIsCustomAttr(item)
          const { groups = [], attr_values = [] } = item || {}
          const list = attr_values?.length ? attr_values : groups
          const childList = isCustomAttr ? list?.reduce((acc, cur) => acc.concat(cur.attr_values), []) : list
          childList?.find(obj => {
            const isMatch = this.isCccxFilterConfig ? obj.nodeId == value.nodeId : obj.attr_filter == label_id
            if (isMatch) {
              isBreak = true
              id = item.p_id || item.attr_id
              obj.isSelected = isSelected
              // 全部取消时，重置id
              !isSelected && !childList.some(item => item.isSelected) && (id = '')
              return true
            }
          })
        })
        : this.Results.filterTsps?.forEach(item => {
          if (isBreak) return
          const list = item?.tagList || []
          list?.forEach(obj => {
            if (obj.tagId == label_id) {
              isBreak = true
              id = item.tagGroupId
              obj.isSelected = isSelected
              // 全部取消时，重置id
              !isSelected && !list.some(item => item.isSelected) && (id = '')
            }
          })
        })

      this.Results.staticFilterId = id
    },
    // 处理属性数据
    handleAttrData (list) {
      const attrNodeIds = new Set()
      const attrFilterArr = new Set()
      const attrValuesArr = new Set()
      const brandNodeIds = new Set()
      const selectAttributeGroup = new Set()

      const addSelectedAttrGroup = (attr) => {
        attr.nodeType == 3 && selectAttributeGroup.add(attr.nodeId)
      }

      list.forEach((attr) => {
        addSelectedAttrGroup(attr)
        
        if (attr.nodeType == 9) {
          brandNodeIds.add(attr.nodeId)
        } else {
          attrNodeIds.add(attr.nodeId)
          attrFilterArr.add(attr.attr_filter)
          attrValuesArr.add(attr.attr_value || attr.attr_value_en || attr.group_name || attr.group_name_en)
        }
      })

      return { attrNodeIds, attrFilterArr, attrValuesArr, brandNodeIds, selectAttributeGroup, addSelectedAttrGroup }
    },
    // 处理属性query
    handleAttrsQuery(newSelectedAttrs) {
      const { attrNodeIds, attrFilterArr, attrValuesArr, brandNodeIds, selectAttributeGroup } = this.handleAttrData(newSelectedAttrs)

      Object.assign(this.queryObj, {
        brand_ids: [...brandNodeIds].join(','),
        attr_values: [...attrValuesArr].join('-'),
        attr_ids: [...attrFilterArr].join('-'),
        attr_node_ids: [...attrNodeIds].join('-'),
        selectAttributeGroup: [...selectAttributeGroup].join('-'),
        exc_attr_id: '',
      })
    },
    // 处理价格区间query
    handlePriceRangeQuery() {
      Object.assign(this.queryObj, { min_price: undefined, max_price: undefined })
    },
    // 处理tsp query
    handleTspQuery (newSelectedTsps) {
      const set = new Set()
      newSelectedTsps?.forEach(item => {
        if (item.nodeType == 7) {
          set.add(item.attr_id + '_' + item.attr_filter)
        } else {
          set.add(item.tagId)
        }
      })
      
      Object.assign(this.queryObj, { tsp_ids: [...set].join(',') })
    },
    // 重置全部筛选
    resetAllFilters() {
      this.clearAllSelectedFilter()
      this.Results.staticFilterId = ''
      this.queryObj = parseQueryString(location.search)
      this.deleteQueryObj(this.retainQueryObj)
      this.handleJumpUrl()
      this.$emit('filterChange', { queryObj: this.queryObj, type: 'resetAll' })

      // 埋点
      filterAna.clickClearAll(this.catInfo)
    },
    // 单个删除顶部选中的筛选词
    delSelectedFilter (value, { delFrom }) {
      this.clearStaticAttrSetId()
      this.deleteSelectedFilter(value)
      const { key, label_id, minPrice, maxPrice } = value
      const delSelectedFilterInfo = {}
      this.queryObj = parseQueryString(location.search)
      if (key == DAILY_KEY) {
        delSelectedFilterInfo.date = label_id
        this.queryObj.daily = undefined
      } else if (key == CATE_KEY) {
        delSelectedFilterInfo.categoryId = label_id
        this.queryObj.child_cat_id = undefined
      } else if (key == ATTR_KEY || key === BRAND_KEY) {
        this.setStaticAttrSetId(value, key)
        delSelectedFilterInfo.attribute = label_id
        const newSelectedAttrs = this.selectedAttrs?.filter(item => {
          if (item.nodeType == 7) return false
          if (item.nodeId) return item.nodeId !== label_id
          
          return item.attr_filter !== label_id
        }) || []
        this.handleAttrsQuery(newSelectedAttrs)
      } else if (key == PRICE_KEY) {
        delSelectedFilterInfo.priceRange = `${minPrice}\`${maxPrice}`
        this.handlePriceRangeQuery()
      } else if (key == TSP_KEY) {
        this.setStaticAttrSetId(value, key)
        delSelectedFilterInfo.tsps = label_id
        const newSelectedTsps = []
        this.selectedTsps?.forEach(item => item.tagId !== label_id && newSelectedTsps.push(item))
        this.selectedAttrs?.forEach(item => {
          if (item.nodeType != 7) return
          const tspId = item.attr_id + '_' + item.attr_filter
          tspId != label_id && newSelectedTsps.push(item)
        })
        this.handleTspQuery(newSelectedTsps)
      }
      // 头部筛选词，点击的删除才记录
      if (delFrom === 'filterWords') {
        Object.assign(this.queryObj, { source: 'insideFilter', sourceStatus: 0 })
      }

      this.handleJumpUrl()
      this.$emit('filterChange', { queryObj: this.queryObj })

      // 埋点
      filterAna.delSelectedFilter(delSelectedFilterInfo)
    }
  },
}
